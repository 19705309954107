<template>
  <div :id="dropdownId" class="w-full">
    <div class="flex w-full flex-col" v-if="droppedDown">
      <BaseTextbox
        v-if="inPlaceSearch"
        v-model="search"
        :class="`sticky top-0 w-full border-grid bg-gray-900 py-2 outline-none`"
        placeholder="Search"
      />
      <div class="max-h-36 w-full overflow-auto">
        <AlertsDeliveryNewChannel
          v-if="showModal"
          :show="true"
          @save="selectedMetric($event)"
          @close="
            showModal = false;
            $emit('close', false);
          "
          :alert-detail="alertDetail"
        />
        <div v-for="item in filteredItems" :key="item.id" class="border-b border-gray-800 px-2 py-3">
          <div class="cursor-pointer" @click.prevent="selectedMetric(item.id)" v-html="item.html"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import AlertsDeliveryNewChannel from '@/components/alerts/delivery/AlertsDeliveryNewChannel.vue';
import { useMapGetter } from '@/store/map-state';

const { channels } = useMapGetter();
const $emit = defineEmits(['close', 'select']);
const plusIcon = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>`;
const defaultItem = {
  id: 'new',
  html: `<div class="py-0.5 flex items-center" style="margin-left: 2px"> ${plusIcon} <span class="mt-0.5 ml-0.5">Create New Chat</span></div>`
};

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array,
    default: () => []
  },
  alertDetail: {
    type: Object,
    default: () => {}
  },
  activeChatOption: {
    type: Object,
    default: () => {}
  },
  searchText: {
    type: String,
    default: ''
  },
  searchDropdown: {
    type: Boolean,
    default: false
  },
  loading: {
    type: Boolean,
    default: false
  },
  inPlaceSearch: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  }
});

const dropdownId = ref(`profile_dropdown_${Math.random()}`);
const droppedDown = ref(false);
const showModal = ref(false);
const search = ref(props.searchText);
const channelItems = ref(props.item);

//METRICS
const filteredItems = computed(() => {
  if (search.value.trim().length > 0) {
    return channelItems.value.filter(i => i.text && i.text.toLowerCase().includes(search.value.toLowerCase()));
  } else {
    return channelItems.value;
  }
});

function selectedMetric(event) {
  if (event == 'new') {
    showModal.value = true;
    let index = channelItems.value.findIndex(i => i.id === 'new');
    channelItems.value.splice(index, 1);
  } else {
    channelItems.value.unshift(defaultItem);
    $emit('select', event);
  }
}

watch(
  () => props.items,
  () => {
    channelItems.value = props.items;
  },
  { immediate: true }
);

// SEARCH
watch(
  () => props.searchText,
  () => {
    search.value = props.searchText;
  }
);

watch(
  () => props.open,
  () => {
    droppedDown.value = props.open;
  }
);
</script>
